<template>
  <a-row class="content-fix org">
    <a-row class="content-title" type="flex">
      <h2>{{ $route.meta.title[$i18n.locale] }}</h2>
      <a-button
        style="margin-left: auto"
        type="primary"
        @click="modalVisible = true"
      >
        <a-icon type="plus" />{{ $t("Add") }}
      </a-button>
    </a-row>
    <a-row type="flex" class="table__filtrs">
      <a-button
        :disabled="cardsIsEmpty"
        type="primary"
        style="margin: 0 0 15px auto"
        @click="removeItem(selectedPosts)"
      >
        <a-icon class="action-btns" type="delete" />
        {{ $t("Delete") }}
      </a-button>
    </a-row>
    <a-table
      :columns="columns"
      :data-source="$store.getters['question/list']"
      :loading="loading"
      :pagination="{ defaultPageSize: 10 }"
      :row-key="() => Math.random().toString().slice(2)"
      class="faqs"
    >
      <template slot="id" slot-scope="item">
        <a-checkbox
          :checked="selectedPosts.includes(+item.id)"
          :value="+item.id"
          @change="toggleItemId(item.id)"
        />
      </template>
      <template slot="position" slot-scope="item">
        <a-input-number
          v-model="item.position"
          style="width: 50px"
          @change="updatePosition(item, $event)"
        />
      </template>

      <template slot="text" slot-scope="item">
        <span
          class="text-center"
          style="display: block; width: 100%; text-align: left"
          >{{ item.text || "Не заполнено" }}</span
        >
      </template>
      <template slot="is_active" slot-scope="item">
        <a-switch
          v-model="item.is_active"
          style="margin-right: 15px"
          @change="checkActive(item, $event)"
        />
        <a-tag :color="item.is_active ? 'geekblue' : 'volcano'">
          {{ item.is_active ? "Опубликован" : "Не опубликован" }}
        </a-tag>
      </template>
      <template slot="operation" slot-scope="item">
        <a-button
          type="primary"
          class="edit-btn"
          @click="$router.push({ name: 'question-detail', params: { id: item.id } })"
        >
          {{ $t('Answers') }}
        </a-button>
        <a-button
          type="primary"
          class="edit-btn"
          @click="edit(item.id)"
        >
          <!-- {{ $t("Edit") }} -->
          <a-icon type="edit" class="action-btns" />
        </a-button>
      </template>
    </a-table>
    <a-modal
      :visible="modalVisible"
      :title="$t('Add')"
      :closable="false"
      style="max-height: 500px; max-width: 900px"
      width="900px"
    >
      <a-row align="middle" class="flex-baseline" style="margin-bottom: 15px">
        <a-col :span="6" style="text-align: right; padding-right: 8px">
          {{ $t('Lang') }}:
        </a-col>
        <a-col :span="12">
          <a-row align="middle" class="flex-baseline flex-wrap">
            <lang-tabs v-model="langTab" class="mb-1x" />
          </a-row>
        </a-col>
      </a-row>
      <a-row>
        <a-col
          :span="6"
          class="form_title"
          style="text-align: right; padding-right: 8px"
        >
          {{ $t('QuestionText') }}:
        </a-col>
        <a-col :span="12" class="sm-mb">
          <a-textarea
            v-model="form.text[getKey]"
            :auto-size="{ minRows: 2, maxRows: 3 }"
            :placeholder="$t('Placeholder')"
            class="form_title-input"
          />
        </a-col>
      </a-row>
      <a-row>
        <a-col
          :span="6"
          class="form_title"
          style="text-align: right; padding-right: 8px"
        >
          {{ $t('MenuPosition') }}:
        </a-col>
        <a-col :span="12" class="sm-mb">
          <a-input
            v-model.number="form.position"
            :placeholder="$t('PositionEnter')"
            spellcheck="false"
          />
        </a-col>
      </a-row>
      <a-row>
        <a-col
          :span="6"
          class="form_title"
          style="text-align: right; padding-right: 8px"
        >
          {{ $t("Status") }}:
        </a-col>
        <a-col :span="12" class="sm-mb">
          <a-switch v-model="form.is_active" />
        </a-col>
      </a-row>
      <template slot="footer">
        <a-button key="back" @click="close()"> {{ $t('Close') }} </a-button>
        <a-button type="primary" @click="save">{{ $t('Save') }}</a-button>
      </template>
    </a-modal>
  </a-row>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      columns: [
        {
          key: "id",
          scopedSlots: { customRender: "id" },
        },
        {
          title: this.$t('MenuPosition'),
          key: "position",
          scopedSlots: { customRender: "position" },
          className: "text-center font-md",
        },
        {
          title: this.$t('Question'),
          key: "text",
          scopedSlots: { customRender: "text" },
          width: "60%",
        },
        {
          title: this.$t("Status"),
          key: "is_active",
          width: "20%",
          scopedSlots: { customRender: "is_active" },
        },
        {
          title: this.$t('UsersColTitle2'),
          key: "operation",
          scopedSlots: { customRender: "operation" },
          width: "20%",
        },
      ],
      selectedRowKeys: [],
      selectedPosts: [],
      cardsIsEmpty: true,
      modalVisible: false,
      langTab: $langPrefix,
      form: {
        text: {
          uz: "",
          oz: "",
          ru: "",
          en: "",
        },
        is_active: true,
        position: 1,
      },
    };
  },
  watch: {
    $route: {
      handler: "fetchData",
      immediate: true,
    },
  },
  computed: {
    getKey() {
      return this.langTab;
    },
  },
  methods: {
    updatePosition(item, position) {
      this.loading = true;
      this.$api
        .put(`/admin/question/${item.id}`, {
          position,
        })
        .then(() => {
          this.loading = false;
          this.fetchData();
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
    },
    toggleItemId(itemId) {
      const f = this.selectedPosts.findIndex((item) => +item == +itemId);
      if (f != -1) {
        this.selectedPosts.splice(f, 1);
        this.cardsIsEmpty = false;
      } else {
        this.selectedPosts.push(itemId);
        this.cardsIsEmpty = false;
      }
      if (this.selectedPosts.length == 0) {
        this.cardsIsEmpty = true;
      }
    },
    async checkActive(item, event) {
      try {
        await this.$api.put(`/admin/question/${item.id}`, {
          is_active: event,
        });
      } catch (error) {
        console.error(error);
      }
    },
    async fetchData() {
      this.loading = true;
      try {
        await this.$store.dispatch("question/fetch");
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
      this.loading = false;
    },
    async removeItem(id) {
      if (Array.isArray(id)) {
        for (let i of id) {
          try {
            const { status } = await this.$store.dispatch("question/remove", i);
            if (status) {
              this.$message.success("Успешно удаленно");
              this.fetchData();
            }
          } catch (e) {
            this.$sentry.captureMessage(e);
          }
        }
      } else {
        try {
          const res = await this.$store.dispatch("question/remove", id);
          if (res) {
            this.$message.success("Успешно удаленно");
            this.fetchData();
          }
        } catch (e) {
          this.$sentry.captureMessage(e);
        }
      }
      this.selectedCards = [];
      this.cardsIsEmpty = true;
    },
    async save() {
      if (this.form.id) {
        await this.$api.put(`/admin/question/${this.form.id}`, this.form);
      } else {
        await this.$api.post("/admin/question/create", this.form);
      }
      this.modalVisible = false;
      this.fetchData();
      this.close()
    },
    async edit(value) {
      const { data } = await this.$api.get(`/admin/question/${value}`)
      this.form.id = data && data.data && data.data.id
      this.form.text = data && data.data && data.data.text
      this.form.is_active = data && data.data && data.data.is_active
      this.form.position = data && data.data && data.data.position
      this.modalVisible = true
    },
    close(){
      this.form = {
        text: {
          uz: "",
          oz: "",
          ru: "",
          en: "",
        },
        is_active: true,
        position: 1,
      }
      this.modalVisible = false
    }
  },
};
</script>

<style>
.faqs .ant-btn-primary {
  background-color: #26a6de;
}
</style>
